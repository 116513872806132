/* $rayloBlue: '#404EFF';
$rayloBlue2Light: '#EFF1FE';
$rayloBlue2Dark: '#989CFF';
$rayloBlue3Light: '#BEBFFF';
$rayloBlue3Dark: '#7277FF';

$rayloPink: '#FFCEC7';
$rayloPink2Light: '#FFFDFE';
$rayloPink2Dark: '#FFBFB5';
$rayloPink3Light: '#FFEAE5';
$rayloPink3Dark: '#ED7D7B';

$rayloLight: '#FFF';
$raylo2Light: '#F4F4F4';
$raylo3Light: '#A0A0A0';

$rayloDark: '#2D2D2D';
$raylo2Dark: '#ABABAB';
$raylo3Dark: '#6C6C6C';

$rayloBGDark: '#060606';
$black: '#000'; */

.checkout {
  &--index {
    min-height: 100vh;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 75px 0;
    width: 50vw;
  }

  &--header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(50vw - 15px);
    z-index: 100;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  &--footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50vw;
    z-index: 50;
  }

  &--main {
    flex: 1;
    z-index: 20;

    .raylo-modal {
      width: 50vw;
      height: 100vh;
      @media screen and (max-width: 1024px) {
        width: 100vw;
      }
    }
  }

  &--step {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 20px;
    position: relative;
    transition: height 200ms ease-in-out;
  }

  &--sidebar {
    background: #404eff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    /* transition: width 200ms ease-in-out; */

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    scrollbar-color: #ffcec7 #404eff;
    scrollbar-width: thin;
  }

  @media screen and (max-width: 1024px) {
    .checkout--header {
      background: none;
      width: 100vw;
    }
    .checkout--index:not(.success) {
      width: 100vw;
      .checkout--sidebar {
        width: 100vw;
        z-index: 75;
        transform: translateY(-200%);
        transition: transform 200ms ease-in-out;
      }
      .checkout--footer {
        width: 100vw;
      }
      &.showSummary .checkout--sidebar {
        transform: translateY(0px);
        overflow: auto;
        height: auto;
        min-height: calc(100vh - 75px);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .checkout--index.showSummary {
    padding-bottom: 0;
    .checkout--main,
    .checkout--footer {
      display: none;
    }
    .checkout--sidebar {
      position: relative;
    }
  }
}

.checkout--index.showConfigure {
  width: 100vw;
  background: #404eff;

  .checkout--sidebar {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    height: auto;
  }
  .checkout--main {
    display: none;
  }
  .checkout--footer {
    display: none;
  }
  .checkout--header {
    background: none;
    width: 100vw;
  }
  .checkout--sidebar {
    transform: none !important;
    position: relative;
  }
}

.checkout--index.success {
  background: #404eff;
  .checkout--step {
    max-width: 350px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .checkout--step,
  .checkout--sidebar {
    transition: none;
  }

  @media screen and (max-width: 700px) {
    width: 100vw;
    flex-direction: column;
    .checkout--main {
      flex: auto;
      height: auto;
      min-height: auto;
    }
    .checkout--sidebar {
      position: relative;
      height: auto;
      min-height: auto;
    }
  }
}

.phone-configure {
  &--image {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 100vh;
  }
  &--form {
    margin-left: 50vw;
    width: calc(50vw - 40px);
  }
  @media screen and (max-width: 1024px) {
    min-width: 400px;
    .phone-configure--image {
      position: relative;
      display: none;
    }
    .phone-configure--form {
      width: auto;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 540px) {
    width: 100%;
  }
  @media screen and (max-width: 400px) {
    min-width: 0px;
  }
}

.blocks-animation {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;
  padding: 40px;
  > div {
    width: 80% !important;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
