/* FADE IN */

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-in-appear {
  opacity: 0;
}

.fade-in-appear-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

/* SLIDE DOWN FADE IN */

.slide-down-fade-in-enter {
  transform: translate3d(0, -40px, 0);
  opacity: 0;
}

.slide-down-fade-in-enter-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform .8s ease-out, opacity .8s ease-out .3s;
}

.slide-down-fade-in-appear {
  transform: translate3d(0, -40px, 0);
  opacity: 0;
}

.slide-down-fade-in-appear-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform .8s ease-out, opacity .8s ease-out .3s;
}

.slide-down-fade-in-exit {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.slide-down-fade-in-exit-active {
  transform: translate3d(0, -40px, 0);
  opacity: 0;
  transition: all .5s;
}


/* SLIDE RIGHT  */

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-40px, 0, 0);
}

.slide-right-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
}

.slide-right-appear {
  opacity: 0;
  transform: translate3d(-40px, 0, 0);
}

.slide-right-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
}

/* SLIDE LEFT  */

.slide-left-enter {
  opacity: 0;
  transform: translate3d(40px, 0, 0);
}

.slide-left-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
}

.slide-left-appear {
  opacity: 0;
  transform: translate3d(40px, 0, 0);
}

.slide-left-appear-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
}
