@import './colors.css';

.upgrade-subscription {
  &__header {
    background: #4252ff;
  }

  &__title {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 75px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1220px;
    margin: 0 auto;
    padding: 75px 40px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      padding: 20px 40px 40px 40px;
      .upgrade-subscription__left {
        order: 2;
      }
      .upgrade-subscription__right {
        order: 1;
        margin-bottom: 30px;
      }
    }
  }

  &__details {
    background: white;
    &-container {
      max-width: 1220px;
      margin: 0 auto;
      padding: 75px 40px;
    }
  }

  &__configure {
    overflow: hidden;
    background: #4252ff;
    position: relative;
    &-container {
      max-width: 1220px;
      margin: 0 auto;
    }
    padding: 0 0 75px 0;
    .phone-configure {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 1024px) {
        justify-content: center;
        .phone-configure--form {
          display: flex;
          justify-content: center;
        }
        .phone-configure--image {
          display: block;
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        .phone-configure--image {
          margin-bottom: 30px;
        }
      }
      &--image,
      &--form {
        flex: 1;
        margin: 0;
        width: auto;
        position: relative;
        height: auto;
      }
      &--form {
        min-height: 700px;
      }
      &--container {
        padding: 0 20px;
        margin-bottom: 0;
      }
    }
  }

  &__back {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 0.875em;
    color: $rayloPink;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: none;
    img {
      margin-right: 10px;
    }
  }
  &__phone,
  &__intro {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: 500;
    font-size: 1.5em;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 36px;
  }
  &__intro {
    color: $rayloPink;
  }
}
