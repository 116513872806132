@import "./colors.css";

.phone-features {
  background: $rayloBlue2Light;



  &--container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 50px;


    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

  }

  &--header, &--list {
    flex: 1;
  }

  &--header {
    color: $rayloDark;
    margin-bottom: 30px;
    text-align: left;
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-size: 1.375em;
    line-height: 2em;
  }
  &--list {
    display: grid;
    min-height: 262px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 70px;
  }

  &--feature {
    color: $rayloDark;
    text-align: left;
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-size: 1em;
    line-height: 2em;
  }
  &__image {
    background: #eee;
    width: 30px;
    height: 30px;
    margin: 0 auto 10px auto;
  }


}