/* $rayloBlue: '#404EFF';
$rayloBlue2Light: '#EFF1FE';
$rayloBlue2Dark: '#989CFF';
$rayloBlue3Light: '#BEBFFF';
$rayloBlue3Dark: '#7277FF';

$rayloPink: '#FFCEC7';
$rayloPink2Light: '#FFFDFE';
$rayloPink2Dark: '#FFBFB5';
$rayloPink3Light: '#FFEAE5';
$rayloPink3Dark: '#ED7D7B';

$rayloLight: '#FFF';
$raylo2Light: '#F4F4F4';
$raylo3Light: '#A0A0A0';

$rayloDark: '#2D2D2D';
$raylo2Dark: '#ABABAB';
$raylo3Dark: '#6C6C6C';

$rayloBGDark: '#060606';
$black: '#000'; */

.checkout--index {
    min-height: 100vh;
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 75px 0;
    width: 50vw;
  }

.checkout--header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(50vw - 15px);
    z-index: 100;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 1) 100%
    );
  }

.checkout--footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 50vw;
    z-index: 50;
  }

.checkout--main {
    flex: 1;
    z-index: 20;
  }

.checkout--main .raylo-modal {
      width: 50vw;
      height: 100vh;
    }

@media screen and (max-width: 1024px) {

.checkout--main .raylo-modal {
        width: 100vw
    }
      }

.checkout--step {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 20px;
    position: relative;
    transition: height 200ms ease-in-out;
  }

.checkout--sidebar {
    background: #404eff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }

/* transition: width 200ms ease-in-out; */

.checkout--sidebar::-webkit-scrollbar {
      width: 9px;
    }

.checkout--sidebar::-webkit-scrollbar-track {
      background: none;
    }

.checkout--sidebar {

    scrollbar-color: #ffcec7 #404eff;
    scrollbar-width: thin;
}

@media screen and (max-width: 1024px) {
    .checkout .checkout--header {
      background: none;
      width: 100vw;
    }
    .checkout .checkout--index:not(.success) {
      width: 100vw;
    }
      .checkout .checkout--index:not(.success) .checkout--sidebar {
        width: 100vw;
        z-index: 75;
        -webkit-transform: translateY(-200%);
                transform: translateY(-200%);
        transition: -webkit-transform 200ms ease-in-out;
        transition: transform 200ms ease-in-out;
        transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
      }
      .checkout .checkout--index:not(.success) .checkout--footer {
        width: 100vw;
      }
      .checkout .checkout--index:not(.success).showSummary .checkout--sidebar {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        overflow: auto;
        height: auto;
        min-height: calc(100vh - 75px);
      }
  }

@media screen and (max-width: 1024px) {
  .checkout--index.showSummary {
    padding-bottom: 0;
  }
    .checkout--index.showSummary .checkout--main,
    .checkout--index.showSummary .checkout--footer {
      display: none;
    }
    .checkout--index.showSummary .checkout--sidebar {
      position: relative;
    }
}

.checkout--index.showConfigure {
  width: 100vw;
  background: #404eff;
}

.checkout--index.showConfigure .checkout--sidebar {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    height: auto;
  }

.checkout--index.showConfigure .checkout--main {
    display: none;
  }

.checkout--index.showConfigure .checkout--footer {
    display: none;
  }

.checkout--index.showConfigure .checkout--header {
    background: none;
    width: 100vw;
  }

.checkout--index.showConfigure .checkout--sidebar {
    -webkit-transform: none !important;
            transform: none !important;
    position: relative;
  }

.checkout--index.success {
  background: #404eff;
}

.checkout--index.success .checkout--step {
    max-width: 350px;
    padding-left: 20px;
    padding-right: 20px;
  }

.checkout--index.success .checkout--step,
  .checkout--index.success .checkout--sidebar {
    transition: none;
  }

@media screen and (max-width: 700px) {

.checkout--index.success {
    width: 100vw;
    flex-direction: column
}
    .checkout--index.success .checkout--main {
      flex: auto;
      height: auto;
      min-height: auto;
    }
    .checkout--index.success .checkout--sidebar {
      position: relative;
      height: auto;
      min-height: auto;
    }
  }

.phone-configure--image {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 100vh;
  }

.phone-configure--form {
    margin-left: 50vw;
    width: calc(50vw - 40px);
  }

@media screen and (max-width: 1024px) {

.phone-configure {
    min-width: 400px
}
    .phone-configure .phone-configure--image {
      position: relative;
      display: none;
    }
    .phone-configure .phone-configure--form {
      width: auto;
      margin-left: 0;
    }
  }

@media screen and (max-width: 540px) {

.phone-configure {
    width: 100%
}
  }

@media screen and (max-width: 400px) {

.phone-configure {
    min-width: 0px
}
  }

.blocks-animation {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;
  padding: 40px;
}

.blocks-animation > div {
    width: 80% !important;
  }

@media screen and (max-width: 1024px) {

.blocks-animation {
    display: none
}
  }

/* FADE IN */

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-in-appear {
  opacity: 0;
}

.fade-in-appear-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

/* SLIDE DOWN FADE IN */

.slide-down-fade-in-enter {
  -webkit-transform: translate3d(0, -40px, 0);
          transform: translate3d(0, -40px, 0);
  opacity: 0;
}

.slide-down-fade-in-enter-active {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: opacity .8s ease-out .3s, -webkit-transform .8s ease-out;
  transition: transform .8s ease-out, opacity .8s ease-out .3s;
  transition: transform .8s ease-out, opacity .8s ease-out .3s, -webkit-transform .8s ease-out;
}

.slide-down-fade-in-appear {
  -webkit-transform: translate3d(0, -40px, 0);
          transform: translate3d(0, -40px, 0);
  opacity: 0;
}

.slide-down-fade-in-appear-active {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: opacity .8s ease-out .3s, -webkit-transform .8s ease-out;
  transition: transform .8s ease-out, opacity .8s ease-out .3s;
  transition: transform .8s ease-out, opacity .8s ease-out .3s, -webkit-transform .8s ease-out;
}

.slide-down-fade-in-exit {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}

.slide-down-fade-in-exit-active {
  -webkit-transform: translate3d(0, -40px, 0);
          transform: translate3d(0, -40px, 0);
  opacity: 0;
  transition: all .5s;
}

/* SLIDE RIGHT  */

.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate3d(-40px, 0, 0);
          transform: translate3d(-40px, 0, 0);
}

.slide-right-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
  transition: transform .8s ease-out, opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
}

.slide-right-appear {
  opacity: 0;
  -webkit-transform: translate3d(-40px, 0, 0);
          transform: translate3d(-40px, 0, 0);
}

.slide-right-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
  transition: transform .8s ease-out, opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
}

/* SLIDE LEFT  */

.slide-left-enter {
  opacity: 0;
  -webkit-transform: translate3d(40px, 0, 0);
          transform: translate3d(40px, 0, 0);
}

.slide-left-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
  transition: transform .8s ease-out, opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
}

.slide-left-appear {
  opacity: 0;
  -webkit-transform: translate3d(40px, 0, 0);
          transform: translate3d(40px, 0, 0);
}

.slide-left-appear-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
  transition: transform .8s ease-out, opacity .6s ease-in .2s;
  transition: transform .8s ease-out, opacity .6s ease-in .2s, -webkit-transform .8s ease-out;
}


.ReactModal__Overlay {
  background-color: rgba(0,0,0, 0.75) !important;
  z-index: 20;
}
.condition-modal {
  background: black;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 500px;
  min-width: 320px;
  position: absolute;
  text-align: center;
  outline: none;
}
.condition-modal--inner {
    padding: 40px;
    max-height: 80vh;
    overflow-y: scroll;
    scrollbar-color: #FFCEC7 #000;
    scrollbar-width: thin;
  }
.condition-modal--inner::-webkit-scrollbar {
      width: 9px;
    }
.condition-modal--inner ::-webkit-scrollbar-track {
      background: none;
    }
.condition-modal--inner::-webkit-scrollbar-thumb {
      background: #FFCEC7;
      border: 0;
      box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    }
.condition-modal--icon { width: 40px }
.condition-modal--close {
    background: #FFCEC7;
    padding: 10px; 
    position: absolute;
    width: 40px;
    height: 40px;
    top: -40px;
    right: -40px;
    cursor: pointer;
    z-index: 60;
  }
@media screen and (max-width: 400px) {
.condition-modal--close {
      top: 0px;
      right: 0px
  }
    }
.condition-modal h2 {
    color: white;
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1em;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    margin-bottom: 20px;
  }
.condition-modal p + h3 {
    margin-top: 2em;
  }
.condition-modal h3 {
    color: #FFCEC7;
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 0.875em;
    line-height: 1.75em;
    margin-bottom: 1em;
    text-align: left;
  }
.condition-modal p {
    color: white;
    font-size: 0.875em;
    line-height: 1.75em;
    text-align: left;
    margin-bottom: 1em;
  }
.condition-modal p a {
      color: #404EFF;
    }

.upgrade-decision {
  border-top: 1px solid rgba(255,255,255,0.4);
  padding-top: 40px;
  margin-top: 40px;

}

  @media screen and (max-width: 700px) {
.upgrade-decision {
    margin-left: calc((100vw - 310px) / -2);
    margin-right: calc((100vw - 310px) / -2);
    border-top: none;
    background: #000;
    padding-bottom: 40px

}

    .upgrade-decision .upgrade-decision__inner {
      max-width: 310px;
      width: 100%;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 350px) {
.upgrade-decision {
    margin-left: calc((100vw - (100vw - 40px)) / -2);
    margin-right: calc((100vw - (100vw - 40px)) / -2);
    padding: 40px 20px

}
  }

  .upgrade-decision__title {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 1.1875em;
    color: #FFFFFF;
    letter-spacing: -0.21px;
    line-height: 32px;
  }

  .upgrade-decision__tip {
    margin-top: 3em;
  }

  .upgrade-decision__warning {
    border-top: 1px solid rgba(255,255,255,0.4);
    border-bottom: 1px solid rgba(255,255,255,0.4);
    padding: 40px 0 20px 0;
    margin: 40px 0;
    display: flex;
    align-items: baseline;

  }

  .upgrade-decision__warning::before {
      content: "!";
      color: #FFCEC7;
      border: 1px solid #FFCEC7;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      width: 25px;
      height: 25px;
      margin-right: 25px;
      font-size: 13px;
    }

  .upgrade-decision__warning p {
      font-family: 'Raylo-Regular', Arial, sans-serif;
      font-size: 0.875em;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 180%;
      margin-bottom: 2em;
      
    }

  .upgrade-decision__warning p strong {
        font-weight: normal;
        color: #FFCEC7;
      }

  .upgrade-decision ol {
    counter-reset: raylo-counter;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  .upgrade-decision li {
    counter-increment: raylo-counter;
    display: flex;
    align-items: baseline;
  }

  .upgrade-decision li::before {
      content: counter(raylo-counter);
      color: #FFCEC7;
      border: 1px solid #FFCEC7;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      width: 25px;
      height: 25px;
      margin-right: 25px;
      font-size: 13px;
    }

  .upgrade-decision li > div > p {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 0.875em;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 180%;
    margin-bottom: 2em;
  }

  .upgrade-decision li > div > p.pink {
      color: #FFCEC7;
    }

  .upgrade-decision li > div > p a {
      white-space: normal;
    }

.footer {

  background-color: #2D2D2D;
  padding: 55px 0;
  position: relative;
}

  @media (max-width: 560px) {
.footer {
    padding: 28px 0
}
  }

  .footer--container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 40px;
  }

  @media (max-width: 560px) {

  .footer--container {
      padding: 0 20px
  }
    }

  .footer .raylo-pill {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }

  .footer--grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer--address {
    flex: 1;
  }

  .footer--address p {
      max-width: 260px;
      font-size: 11px;
      color: #ABABAB;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 20px;
    }

  .footer--address p strong {
        display: block;
      }

  .footer--links {
    width: 100%;
    max-width: 240px;
    margin-left: 20px;
    margin-top: 20px;
  }

  @media screen and (max-width: 920px) {
    .footer .footer--grid {
      margin: 0 -20px;
    }
    .footer .footer--address, .footer .footer--links {
      flex: 1;
      max-width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
    .footer .footer--address {
      flex: 1.5;
    }
  }

  @media screen and (max-width: 650px) {
    .footer .footer--grid {
      margin: 0 -20px;
      flex-wrap: wrap;
    }
    .footer .footer--links {
      min-width: 40%;
    }
    .footer .footer--address {
      margin-top: 80px;
      order: 4;
      flex-shrink: 0;
      width: 100%;
    }
      .footer .footer--address p {
        max-width: 100%;
      }

  }

  .footer__title {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .footer__link {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #FFCEC7;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
  }

  .footer__link:hover {
      color: #FFF;
    }

  .footer--legal {
    color: #ABABAB;
    font-size: 11px;
  }

  .footer--lower {
    border-top: 1px solid #6C6C6C;
    margin-top: 80px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer .social-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .footer .social-icons a {
      margin-left: 10px;
    }

.device-switcher {
  position: relative;
}

  .device-switcher__wrapper {
    background: #2D2D2D;
    position: relative;
  }

  .device-switcher__container {
    max-width: 1220px;
    margin: 0 auto;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 40px;
    width: 100%;
    position: relative;
  }

  .device-switcher__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 0;
    display: none;
  }

  .device-switcher__toggle {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 1.125em;
    color: #ffffff;
    line-height: 1.75;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  @media screen and (max-width: 768px) {

  .device-switcher__toggle {
      justify-content: space-between
  }
    }

  .device-switcher__toggle-phone {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 20px;
    }

  .device-switcher__toggle-phone img {
        width: 40px;
        margin-right: 20px;
      }

  .device-switcher__toggle-title {
      margin-right: 20px;
    }

  .device-switcher__toggle-arrow {
      transition: all 0.2s ease-in-out;
    }

  .device-switcher__drawer {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 90px;
    padding-bottom: 15px;
    background: #2D2D2D;
    z-index: 100;
  }

  .device-switcher__drawer-container {
      position: relative;
      max-width: 1220px;
      padding: 0 40px;
      margin: 0 auto;
      width: 100%;
      z-index: 10;
    }

  .device-switcher__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {

  .device-switcher__list {
      display: block
  }
    }

  .device-switcher__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    margin-right: 25px;
    margin-bottom: 25px;
    padding: 20px;
    min-width: 300px;
    border-bottom: 2px solid transparent;
  }

  .device-switcher__item.active {
      border-bottom-color: #FFCEC7;
    }

  .device-switcher__item:hover {
      border-bottom-color: #FFCEC7;
    }

  @media screen and (max-width: 768px) {

  .device-switcher__item {
      margin-right: 0
  }
    }

  .device-switcher__phone {
    margin-right: 20px;
  }

  .device-switcher.open .device-switcher__overlay {
      display: block;
    }

  .device-switcher.open .device-switcher__drawer {
      display: flex;
    }

  .device-switcher.open .device-switcher__toggle-arrow {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
    }


.phone-boxes__tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px;
    position: relative;
  }

    .phone-boxes__tabs::after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      border-bottom: 1px solid rgba(0,0,0, 0.07);
    }

    .phone-boxes__tabs-tab {
      margin-right: 40px;
      cursor: pointer;
      -webkit-appearance: none;
              appearance: none;
      display: block;
      position: relative;
      z-index: 20;
      font-family: 'Raylo-Medium', Arial, sans-serif;
      font-size: 16px;
      border: none;
      background: none;
      color: #ABABAB;
      line-height: 36px;
      border-bottom: 1px solid transparent;
      padding: 0 0 10px 0;
    }

    .phone-boxes__tabs-tab:hover {
        color: #6C6C6C;
        border-bottom: 1px solid #6C6C6C;
      }

    .phone-boxes__tabs-tab.active {
        color: #404EFF;
        border-bottom: 1px solid #404EFF;
      }

    .phone-boxes__tabs-tab:last-child {
        margin-right: 0;
      }

    @media (max-width: 600px) {
      .phone-boxes__tabs-tab {
        margin-right: 20px;
        font-size: 14px;
      }
        .phone-boxes__tabs-tab.active {
          color: #2d2d2d;
        }
    }

    @media (max-width: 320px) {
      .phone-boxes__tabs-tab {
        margin-right: 16px;
        font-size: 10px;
      }
    }
  .phone-boxes__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }
  .phone-boxes__list .phone-box {
      flex: 1;
      min-width: 330px;
    }
  @media screen and (max-width: 773px) {
  .phone-boxes__list .phone-box {
        min-width: 250px
    }
      }
  @media screen and (max-width: 686px) {
  .phone-boxes__list {
      grid-template-columns: 1fr
  }
      .phone-boxes__list .phone-box {
        min-width: auto;
      }
    }
.phone-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0px 20px 0px;
  background: #2D2D2D;
}
.phone-box__header,
  .phone-box__price,
  .phone-box__features,
  .phone-box__image {
    padding: 0 30px;
    width: 100%;
  }
.phone-box__header {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 26px;
    margin-bottom: 5px;
  }
.phone-box__header em {
      font-style: normal;
      opacity: 0.5;
    }
.phone-box__price {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 14px;
    color: #FFCEC7;
    text-align: center;
    margin-bottom: 20px;
  }
.phone-box__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin: 20px 0;
  }
.phone-box__image img {
      max-width: 100%;
    }
.phone-box__button {
    padding: 20px 0 20px 0;
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.phone-box__features-inner {
    opacity: 0.9;
    width: 100%;
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    border-top: 1px solid rgba(171,171,171, 0.2);
    margin-top: 10px;
  }
.phone-box__features-inner ul {
      margin-left: 0;
      padding-left: 20px;
      list-style: none;
    }
.phone-box__features-inner li::before {
      color: #FFCEC7;
      font-size: 12px;
      content: '•';
      display: inline-block;
      vertical-align: 2px;
      width: 20px;
      margin-left: -20px;
    }
.phone-box.blue {
    background-color: #EFF1FE;
  }
.phone-box.blue .phone-box__header {
        color: #2D2D2D;
      }
.phone-box.blue .phone-box__price {
        color: #404EFF;
      }
.phone-box.blue .phone-box__button {
        background: linear-gradient(
          0deg,
          rgba(239,241,254, 1) 30%,
          rgba(239,241,254, 0) 100%
        );
      }
.phone-box.blue .phone-box__button button {
          background: #404EFF;
          border-color: #404EFF;
          color: white;
        }
.phone-box.blue .phone-box__button button:hover {
            background-color: #989CFF;
            border-color: #989CFF;
          }
.phone-box.blue .phone-box__features {
        border-color: rgba(0,0,0, 0.3);
      }
.phone-box.blue .phone-box__features-inner {
          color: rgba(45,45,45, 0.7);
        }
.phone-box.blue .phone-box__features-inner li::before {
            color: #404EFF;
          }
.phone-box.grey {
    background-color: #F7F7F7;
  }
.phone-box.grey .phone-box__header {
        color: #2D2D2D;
      }
.phone-box.grey .phone-box__price {
        color: #404EFF;
      }
.phone-box.grey .phone-box__button {
        background: linear-gradient(
          0deg,
          rgba(247,247,247, 1) 30%,
          rgba(247,247,247, 0) 100%
        );
      }
.phone-box.grey .phone-box__button button {
          background: #404EFF;
          border-color: #404EFF;
          color: white;
        }
.phone-box.grey .phone-box__button button:hover {
            background-color: #989CFF;
            border-color: #989CFF;
          }
.phone-box.grey .phone-box__features {
        border-color: rgba(0,0,0, 0.3);
      }
.phone-box.grey .phone-box__features-inner {
          color: rgba(45,45,45, 0.7);
        }
.phone-box.grey .phone-box__features-inner li::before {
            color: #b6b6b6;
          }
.phone-box.pink {
    background: #FFEAE5;
  }
.phone-box.pink .phone-box__header {
        color: #2D2D2D;
      }
.phone-box.pink .phone-box__price {
        color: #FF7577;
      }
.phone-box.pink .phone-box__button {
        background: linear-gradient(
          0deg,
          rgba(255,234,229, 1) 30%,
          rgba(255,234,229, 0) 100%
        );
      }
.phone-box.pink .phone-box__button button {
          background: #FF7577;
          border-color: #FF7577;
          color: white;
        }
.phone-box.pink .phone-box__button button:hover {
            background-color: #ED7D7B;
            border-color: #ED7D7B;
          }
.phone-box.pink .phone-box__features {
        border-color: rgba(255,117,119, 0.2);
      }
.phone-box.pink .phone-box__features-inner {
          color: rgba(45,45,45, 0.7);
        }
.phone-box.pink .phone-box__features-inner li::before {
            color: #FF7577;
          }
.phone-box.recommendation {
    margin: 0;
    width: 100%;
    position: relative;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
  }
.phone-box.recommendation .phone-box__features {
      display: none;
    }
.phone-box.recommendation .phone-box__image {
      flex: 1;
    }
@media screen and (min-width: 685px) {
.phone-box.recommendation {
      width: calc((100% - 25px) / 2);
      margin: 0 12.5px
  }
    }
@media screen and (min-width: 1040px) {
.phone-box.recommendation {
      width: calc((100% - 50px) / 3);
      margin: 0 12.5px
  }
    }
@media screen and (max-width: 686px) {
.phone-box.recommendation {
      padding-bottom: 0;
      height: 385px
  }
        .phone-box.recommendation .phone-box__image {
          height: 200px;
          margin-bottom: 0;
          flex: 1;
          align-items: flex-start;
        }
        .phone-box.recommendation .phone-box__header,
        .phone-box.recommendation .phone-box__price {
          padding-left: 30px;
          padding-right: 30px;
        }
        .phone-box.recommendation .phone-box__button {
          background: transparent;
          padding: 0;
          position: absolute;
          bottom: 50px;
          width: 100%;
        }
          .phone-box.recommendation .phone-box__button button {
            background-color: rgba(0,0,0, 0.47);
            border-color: rgba(255,255,255, 0.59);
            color: white;
          }
            .phone-box.recommendation .phone-box__button button:hover {
              background-color: rgba(0,0,0, 0.8);
              border-color: rgba(255,255,255, 0.8);
            }
    }


.all-phone-list {
  background: white;

}

  .all-phone-list__title {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 75px;
  }

  .all-phone-list__container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 75px 40px 75px 40px;
  }
.phone-list__recommended {
    background: #404EFF;
  }
.phone-list__container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 40px 75px 40px;
  }
.phone-list__container .flickity-prev-next-button {
      display: none
    }
.phone-list__container .flickity-page-dots {
      bottom: -40px;
    }
.phone-list__container .flickity-page-dots .dot {
      opacity: 0.4;
      background: white;
    }
.phone-list__container .flickity-page-dots .dot.is-selected {
        opacity: 1;
      }

.upgrade-subscription__header {
    background: #4252ff;
  }
  .upgrade-subscription__title {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 75px;
  }
  .upgrade-subscription__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1220px;
    margin: 0 auto;
    padding: 75px 40px;
  }
  @media screen and (max-width: 768px) {
  .upgrade-subscription__container {
      flex-direction: column;
      align-items: stretch;
      padding: 20px 40px 40px 40px
  }
      .upgrade-subscription__container .upgrade-subscription__left {
        order: 2;
      }
      .upgrade-subscription__container .upgrade-subscription__right {
        order: 1;
        margin-bottom: 30px;
      }
    }
  .upgrade-subscription__details {
    background: white;
  }
  .upgrade-subscription__details-container {
      max-width: 1220px;
      margin: 0 auto;
      padding: 75px 40px;
    }
  .upgrade-subscription__configure {
    overflow: hidden;
    background: #4252ff;
    position: relative;
  }
  .upgrade-subscription__configure-container {
      max-width: 1220px;
      margin: 0 auto;
    }
  .upgrade-subscription__configure {
    padding: 0 0 75px 0;
}
  .upgrade-subscription__configure .phone-configure {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  @media screen and (max-width: 1024px) {
  .upgrade-subscription__configure .phone-configure {
        justify-content: center
    }
        .upgrade-subscription__configure .phone-configure .phone-configure--form {
          display: flex;
          justify-content: center;
        }
        .upgrade-subscription__configure .phone-configure .phone-configure--image {
          display: block;
        }
      }
  @media screen and (max-width: 768px) {
  .upgrade-subscription__configure .phone-configure {
        flex-direction: column
    }
        .upgrade-subscription__configure .phone-configure .phone-configure--image {
          margin-bottom: 30px;
        }
      }
  .upgrade-subscription__configure .phone-configure--image,
      .upgrade-subscription__configure .phone-configure--form {
        flex: 1;
        margin: 0;
        width: auto;
        position: relative;
        height: auto;
      }
  .upgrade-subscription__configure .phone-configure--form {
        min-height: 700px;
      }
  .upgrade-subscription__configure .phone-configure--container {
        padding: 0 20px;
        margin-bottom: 0;
      }
  .upgrade-subscription__back {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 0.875em;
    color: #FFCEC7;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: none;
  }
  .upgrade-subscription__back img {
      margin-right: 10px;
    }
  .upgrade-subscription__phone,
  .upgrade-subscription__intro {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: 500;
    font-size: 1.5em;
    color: #ffffff;
    letter-spacing: 0;
    line-height: 36px;
  }
  .upgrade-subscription__intro {
    color: #FFCEC7;
  }


.phone-features {
  background: #EFF1FE;


}



  .phone-features--container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 50px;


    display: flex;
    justify-content: space-between;
    align-items: flex-start;

  }



  @media screen and (max-width: 900px) {



  .phone-features--container {
      flex-direction: column

  }
    }



  .phone-features--header, .phone-features--list {
    flex: 1;
  }



  .phone-features--header {
    color: #2D2D2D;
    margin-bottom: 30px;
    text-align: left;
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-size: 1.375em;
    line-height: 2em;
  }



  .phone-features--list {
    display: grid;
    min-height: 262px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 70px;
  }



  .phone-features--feature {
    color: #2D2D2D;
    text-align: left;
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-size: 1em;
    line-height: 2em;
  }



  .phone-features__image {
    background: #eee;
    width: 30px;
    height: 30px;
    margin: 0 auto 10px auto;
  }

.certified {
  background: white;
  overflow: hidden;
}

  .certified__container {
    max-width: 1220px;
    padding: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;


  }

  .certified__left, .certified__right {
    flex: 1;
  }

  .certified__left {
    margin-right: 40px;
  }

  .certified__tabs a {
      display: block;
      margin-bottom: 10px;
      opacity: 0.5;
      font-family: 'Raylo-Medium', Arial, sans-serif;
      font-weight: 500;
      font-size: 1.0625em;
      color: #404EFF;
      letter-spacing: 0;
      line-height: 2;
      cursor: pointer;
    }

  .certified__tabs a.active {
        opacity: 1;
      }

  .certified__right {
    display: flex;
  }

  .certified__tab {
    flex: 1;
    max-width: 470px;
    position: relative;
    width: 100%;
  }

  .certified__tab--inner {
      background: white;
      padding: 40px;
      position: relative;
      z-index: 10;
      border: 1px solid #FFCEC7;
    }

  .certified__tab--inner::after {
        content: "";
        width: 100px;
        height: 100px;
        background-color: #F4F4F4;
        position: absolute;
        left: -180px;
        bottom: 80px;
        display: block;
        z-index: 1;
      }

  .certified__tab::before {
      content: "";
      width: 100px;
      height: 100px;
      background-color: #FFBFB5;
      position: absolute;
      top: -20px;
      right: -80px;
      display: block;
      z-index: 1;
    }

  .certified__tab::after {
      content: "";
      width: 100px;
      height: 100px;
      background-color: #F4F4F4;
      position: absolute;
      left: -80px;
      bottom: -20px;
      display: block;
      z-index: 1;
    }

  .certified__tab--header {
      text-align: center;
    }

  .certified__tab--header img {
        width: 30px;
        margin-bottom: 25px;
      }

  .certified__tab p, .certified__tab li {
      font-family: 'Raylo-Regular', Arial, sans-serif;
      font-size: 0.875em;
      line-height: 2;
      color: #2D2D2D;
    }

  .certified__tab p strong, .certified__tab li strong {
        font-family: 'Raylo-Bold', Arial, sans-serif;
        font-weight: bold;
        color: #404EFF;
      }

  .certified__tab p a, .certified__tab li a {
        color: #404EFF;
        text-decoration: none;
      }

  .certified__tab ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }

  .certified__tab ul li {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
      }

  .certified__tab ul li img {
          width: 20px;
          margin-right: 20px; 
        }

  .certified h3 {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.75em;
    color: #000000;
    letter-spacing: 0;
    line-height: 1.5;
    padding-top: 60px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0,0,0,0.1);
    max-width: 400px;
  }

  .certified h4 {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.1875em;
    color: #2D2D2D;
    letter-spacing: 0;
    line-height: 1.5;
    padding-bottom: 70px;
    margin-bottom: 50px;
    border-bottom: 1px solid #FFCEC7;
  }

  @media screen and (max-width: 800px) {

    .certified h3 {
      padding-top: 0;
      border-top: none;
      max-width: 100%;
    }
      .certified .certified__container {
        flex-direction: column;
      }
      .certified .certified__right {
        width: 100%;
      }
      .certified .certified__tabs {
        margin-bottom: 40px;
      }
      .certified .certified__tab {
        max-width: 100%;
        width: 100%;
      }
        .certified .certified__tab--inner {
          width: 100%;
        }

  }

  .certified.newversion h3 {
      padding-top: 0;
      border-top: none;
      max-width: 100%;
    }

  .certified.newversion .certified__container {
      flex-direction: column;
      }

  .certified.newversion .certified__tabs {
        display: none;
      }

  .certified.newversion .certified__right {
        justify-content: space-between;
        align-items: stretch;
        margin: 0 -20px;
      }

  .certified.newversion .certified__tab {
        margin: 0 20px;
      }

  .certified.newversion .certified__tab--header h4 {
            padding-bottom: 40px;
            margin-bottom: 40px;
          }
