@import "./colors.css";

.ReactModal__Overlay {
  background-color: rgba(0,0,0, 0.75) !important;
  z-index: 20;
}


.condition-modal {
  background: black;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  max-width: 500px;
  min-width: 320px;
  position: absolute;
  text-align: center;
  outline: none;

  &--inner {
    padding: 40px;
    max-height: 80vh;
    overflow-y: scroll;
    scrollbar-color: $rayloPink #000;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 9px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: $rayloPink;
      border: 0;
      box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
    }
  }


  &--icon { width: 40px }

  &--close {
    background: $rayloPink;
    padding: 10px; 
    position: absolute;
    width: 40px;
    height: 40px;
    top: -40px;
    right: -40px;
    cursor: pointer;
    z-index: 60;
    @media screen and (max-width: 400px) {
      top: 0px;
      right: 0px;
    }
  }


  h2 {
    color: white;
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1em;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    margin-bottom: 20px;
  }
  p + h3 {
    margin-top: 2em;
  }
  h3 {
    color: $rayloPink;
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 0.875em;
    line-height: 1.75em;
    margin-bottom: 1em;
    text-align: left;
  }
  p {
    color: white;
    font-size: 0.875em;
    line-height: 1.75em;
    text-align: left;
    margin-bottom: 1em;
    a {
      color: $rayloBlue;
    }
  }
}