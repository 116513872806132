@import "./colors.css";


.all-phone-list {
  background: white;

  &__title {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 75px;
  }

  &__container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 75px 40px 75px 40px;
  }

}

.phone-list {

  &__recommended {
    background: $rayloBlue;
  }

  &__container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0px 40px 75px 40px;

    .flickity-prev-next-button {
      display: none
    }
    .flickity-page-dots {
      bottom: -40px;
    }
    .flickity-page-dots .dot {
      opacity: 0.4;
      background: white;
      &.is-selected {
        opacity: 1;
      }
    }
  }

  

}