@import "./colors.css";

.footer {

  background-color: $rayloDark;
  padding: 55px 0;
  position: relative;

  @media (max-width: 560px) {
    padding: 28px 0;
  }

  &--container {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 40px;

    @media (max-width: 560px) {
      padding: 0 20px;
    }
  }

  .raylo-pill {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }

  &--grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &--address {
    flex: 1;
    p {
      max-width: 260px;
      font-size: 11px;
      color: #ABABAB;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 20px;
      strong {
        display: block;
      }
    }
  }
  &--links {
    width: 100%;
    max-width: 240px;
    margin-left: 20px;
    margin-top: 20px;
  }

  @media screen and (max-width: 920px) {
    .footer--grid {
      margin: 0 -20px;
    }
    .footer--address, .footer--links {
      flex: 1;
      max-width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
    .footer--address {
      flex: 1.5;
    }
  }
  
  @media screen and (max-width: 650px) {
    .footer--grid {
      margin: 0 -20px;
      flex-wrap: wrap;
    }
    .footer--links {
      min-width: 40%;
    }
    .footer--address {
      margin-top: 80px;
      order: 4;
      flex-shrink: 0;
      width: 100%;
      p {
        max-width: 100%;
      }
    }

  }
  &__title {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &__link {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #FFCEC7;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    &:hover {
      color: $rayloLight;
    }
  }
  &--legal {
    color: #ABABAB;
    font-size: 11px;
  }
  &--lower {
    border-top: 1px solid #6C6C6C;
    margin-top: 80px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .social-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      margin-left: 10px;
    }
  }
}