@import "./colors.css";

.upgrade-decision {
  border-top: 1px solid rgba(255,255,255,0.4);
  padding-top: 40px;
  margin-top: 40px;

  @media screen and (max-width: 700px) {
    margin-left: calc((100vw - 310px) / -2);
    margin-right: calc((100vw - 310px) / -2);
    border-top: none;
    background: $black;
    padding-bottom: 40px;

    .upgrade-decision__inner {
      max-width: 310px;
      width: 100%;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 350px) {
    margin-left: calc((100vw - (100vw - 40px)) / -2);
    margin-right: calc((100vw - (100vw - 40px)) / -2);
    padding: 40px 20px;
  }
  

  &__title {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 1.1875em;
    color: #FFFFFF;
    letter-spacing: -0.21px;
    line-height: 32px;
  }

  &__tip {
    margin-top: 3em;
  }

  &__warning {
    border-top: 1px solid rgba(255,255,255,0.4);
    border-bottom: 1px solid rgba(255,255,255,0.4);
    padding: 40px 0 20px 0;
    margin: 40px 0;
    display: flex;
    align-items: baseline;

    &::before {
      content: "!";
      color: $rayloPink;
      border: 1px solid $rayloPink;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      width: 25px;
      height: 25px;
      margin-right: 25px;
      font-size: 13px;
    }

    p {
      font-family: 'Raylo-Regular', Arial, sans-serif;
      font-size: 0.875em;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 180%;
      margin-bottom: 2em;
      strong {
        font-weight: normal;
        color: $rayloPink;
      }
      
    }

  }

  ol {
    counter-reset: raylo-counter;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  li {
    counter-increment: raylo-counter;
    display: flex;
    align-items: baseline;
    &::before {
      content: counter(raylo-counter);
      color: $rayloPink;
      border: 1px solid $rayloPink;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      width: 25px;
      height: 25px;
      margin-right: 25px;
      font-size: 13px;
    }
  }

  li > div > p {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 0.875em;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 180%;
    margin-bottom: 2em;

    &.pink {
      color: $rayloPink;
    }

    a {
      white-space: normal;
    }
  }

}