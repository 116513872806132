@import './colors.css';

.device-switcher {
  position: relative;

  &__wrapper {
    background: $rayloDark;
    position: relative;
  }

  &__container {
    max-width: 1220px;
    margin: 0 auto;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 40px;
    width: 100%;
    position: relative;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 0;
    display: none;
  }

  &__toggle {
    font-family: 'Raylo-Regular', Arial, sans-serif;
    font-size: 1.125em;
    color: #ffffff;
    line-height: 1.75;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;

    @media screen and (max-width: 768px) {
      justify-content: space-between;
    }
    &-phone {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 20px;
      img {
        width: 40px;
        margin-right: 20px;
      }
    }
    &-title {
      margin-right: 20px;
    }
    &-arrow {
      transition: all 0.2s ease-in-out;
    }
  }

  &__drawer {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 90px;
    padding-bottom: 15px;
    background: $rayloDark;
    z-index: 100;
    &-container {
      position: relative;
      max-width: 1220px;
      padding: 0 40px;
      margin: 0 auto;
      width: 100%;
      z-index: 10;
    }
  }
  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    margin-right: 25px;
    margin-bottom: 25px;
    padding: 20px;
    min-width: 300px;
    border-bottom: 2px solid transparent;
    &.active {
      border-bottom-color: $rayloPink;
    }
    &:hover {
      border-bottom-color: $rayloPink;
    }
    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }
  &__phone {
    margin-right: 20px;
  }

  &.open {
    .device-switcher__overlay {
      display: block;
    }
    .device-switcher__drawer {
      display: flex;
    }
    .device-switcher__toggle-arrow {
      transform: rotate(180deg);
    }
  }
}
