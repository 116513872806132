@import "./colors.css";

.certified {
  background: white;
  overflow: hidden;

  &__container {
    max-width: 1220px;
    padding: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;


  }



  &__left, &__right {
    flex: 1;
  }
  &__left {
    margin-right: 40px;
  }

  &__tabs {
    a {
      display: block;
      margin-bottom: 10px;
      opacity: 0.5;
      font-family: 'Raylo-Medium', Arial, sans-serif;
      font-weight: 500;
      font-size: 1.0625em;
      color: $rayloBlue;
      letter-spacing: 0;
      line-height: 2;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
    }
  }

  &__right {
    display: flex;
  }

  &__tab {
    flex: 1;
    max-width: 470px;
    position: relative;
    width: 100%;

    &--inner {
      background: white;
      padding: 40px;
      position: relative;
      z-index: 10;
      border: 1px solid $rayloPink;
      &::after {
        content: "";
        width: 100px;
        height: 100px;
        background-color: $raylo2Light;
        position: absolute;
        left: -180px;
        bottom: 80px;
        display: block;
        z-index: 1;
      }
    }

    &::before {
      content: "";
      width: 100px;
      height: 100px;
      background-color: $rayloPink2Dark;
      position: absolute;
      top: -20px;
      right: -80px;
      display: block;
      z-index: 1;
    }
    &::after {
      content: "";
      width: 100px;
      height: 100px;
      background-color: $raylo2Light;
      position: absolute;
      left: -80px;
      bottom: -20px;
      display: block;
      z-index: 1;
    }

    &--header {
      text-align: center;
      img {
        width: 30px;
        margin-bottom: 25px;
      }
    }

    p, li {
      font-family: 'Raylo-Regular', Arial, sans-serif;
      font-size: 0.875em;
      line-height: 2;
      color: $rayloDark;
      strong {
        font-family: 'Raylo-Bold', Arial, sans-serif;
        font-weight: bold;
        color: $rayloBlue;
      }
      a {
        color: $rayloBlue;
        text-decoration: none;
      }
    }

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        img {
          width: 20px;
          margin-right: 20px; 
        }
      }
    }
  }


  h3 {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.75em;
    color: #000000;
    letter-spacing: 0;
    line-height: 1.5;
    padding-top: 60px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0,0,0,0.1);
    max-width: 400px;
  }

  h4 {
    font-family: 'Raylo-Bold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.1875em;
    color: #2D2D2D;
    letter-spacing: 0;
    line-height: 1.5;
    padding-bottom: 70px;
    margin-bottom: 50px;
    border-bottom: 1px solid $rayloPink;
  }

  @media screen and (max-width: 800px) {

    h3 {
      padding-top: 0;
      border-top: none;
      max-width: 100%;
    }

    .certified {
      &__container {
        flex-direction: column;
      }
      &__right {
        width: 100%;
      }
      &__tabs {
        margin-bottom: 40px;
      }
      &__tab {
        max-width: 100%;
        width: 100%;
        &--inner {
          width: 100%;
        }
      }
    }

  }


  &.newversion {
    h3 {
      padding-top: 0;
      border-top: none;
      max-width: 100%;
    }
    .certified {
      &__container {
      flex-direction: column;
      }
      &__tabs {
        display: none;
      }
      &__right {
        justify-content: space-between;
        align-items: stretch;
        margin: 0 -20px;
      }


      &__tab {
        margin: 0 20px;
        &--header {
          h4 {
            padding-bottom: 40px;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}