@import './colors.css';

.phone-boxes {
  &__tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 60px;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      border-bottom: 1px solid rgba(#000, 0.07);
    }

    &-tab {
      margin-right: 40px;
      cursor: pointer;
      appearance: none;
      display: block;
      position: relative;
      z-index: 20;
      font-family: 'Raylo-Medium', Arial, sans-serif;
      font-size: 16px;
      border: none;
      background: none;
      color: $raylo2Dark;
      line-height: 36px;
      border-bottom: 1px solid transparent;
      padding: 0 0 10px 0;
      &:hover {
        color: $raylo3Dark;
        border-bottom: 1px solid $raylo3Dark;
      }
      &.active {
        color: $rayloBlue;
        border-bottom: 1px solid $rayloBlue;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 600px) {
      &-tab {
        margin-right: 20px;
        font-size: 14px;
        &.active {
          color: #2d2d2d;
        }
      }
    }
    @media (max-width: 320px) {
      &-tab {
        margin-right: 16px;
        font-size: 10px;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    .phone-box {
      flex: 1;
      min-width: 330px;
      @media screen and (max-width: 773px) {
        min-width: 250px;
      }
    }
    @media screen and (max-width: 686px) {
      grid-template-columns: 1fr;
      .phone-box {
        min-width: auto;
      }
    }
  }
}

.phone-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0px 20px 0px;
  background: $rayloDark;

  &__header,
  &__price,
  &__features,
  &__image {
    padding: 0 30px;
    width: 100%;
  }

  &__header {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 26px;
    margin-bottom: 5px;
    em {
      font-style: normal;
      opacity: 0.5;
    }
  }
  &__price {
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 14px;
    color: $rayloPink;
    text-align: center;
    margin-bottom: 20px;
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin: 20px 0;
    img {
      max-width: 100%;
    }
  }
  &__button {
    padding: 20px 0 20px 0;
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__features-inner {
    opacity: 0.9;
    width: 100%;
    font-family: 'Raylo-Medium', Arial, sans-serif;
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    border-top: 1px solid rgba($raylo2Dark, 0.2);
    margin-top: 10px;
    ul {
      margin-left: 0;
      padding-left: 20px;
      list-style: none;
    }
    li::before {
      color: $rayloPink;
      font-size: 12px;
      content: '•';
      display: inline-block;
      vertical-align: 2px;
      width: 20px;
      margin-left: -20px;
    }
  }

  &.blue {
    background-color: $rayloBlue2Light;
    .phone-box {
      &__header {
        color: $rayloDark;
      }
      &__price {
        color: $rayloBlue;
      }
      &__button {
        background: linear-gradient(
          0deg,
          rgba($rayloBlue2Light, 1) 30%,
          rgba($rayloBlue2Light, 0) 100%
        );

        button {
          background: $rayloBlue;
          border-color: $rayloBlue;
          color: white;
          &:hover {
            background-color: $rayloBlue2Dark;
            border-color: $rayloBlue2Dark;
          }
        }
      }
      &__features {
        border-color: rgba($black, 0.3);
        &-inner {
          color: rgba($rayloDark, 0.7);
          li::before {
            color: $rayloBlue;
          }
        }
      }
    }
  }

  &.grey {
    background-color: $raylo4Light;
    .phone-box {
      &__header {
        color: $rayloDark;
      }
      &__price {
        color: $rayloBlue;
      }
      &__button {
        background: linear-gradient(
          0deg,
          rgba($raylo4Light, 1) 30%,
          rgba($raylo4Light, 0) 100%
        );

        button {
          background: $rayloBlue;
          border-color: $rayloBlue;
          color: white;
          &:hover {
            background-color: $rayloBlue2Dark;
            border-color: $rayloBlue2Dark;
          }
        }
      }
      &__features {
        border-color: rgba($black, 0.3);
        &-inner {
          color: rgba($rayloDark, 0.7);
          li::before {
            color: #b6b6b6;
          }
        }
      }
    }
  }

  &.pink {
    background: $rayloPink3Light;
    .phone-box {
      &__header {
        color: $rayloDark;
      }
      &__price {
        color: $rayloPink4Dark;
      }
      &__button {
        background: linear-gradient(
          0deg,
          rgba($rayloPink3Light, 1) 30%,
          rgba($rayloPink3Light, 0) 100%
        );

        button {
          background: $rayloPink4Dark;
          border-color: $rayloPink4Dark;
          color: white;
          &:hover {
            background-color: $rayloPink3Dark;
            border-color: $rayloPink3Dark;
          }
        }
      }
      &__features {
        border-color: rgba($rayloPink4Dark, 0.2);
        &-inner {
          color: rgba($rayloDark, 0.7);
          li::before {
            color: $rayloPink4Dark;
          }
        }
      }
    }
  }

  &.recommendation {
    margin: 0;
    width: 100%;
    position: relative;
    min-height: 100%;
    display: flex;
    justify-content: space-between;

    .phone-box__features {
      display: none;
    }
    .phone-box__image {
      flex: 1;
    }

    @media screen and (min-width: 685px) {
      width: calc((100% - 25px) / 2);
      margin: 0 12.5px;
    }
    @media screen and (min-width: 1040px) {
      width: calc((100% - 50px) / 3);
      margin: 0 12.5px;
    }

    @media screen and (max-width: 686px) {
      padding-bottom: 0;
      height: 385px;
      .phone-box {
        &__image {
          height: 200px;
          margin-bottom: 0;
          flex: 1;
          align-items: flex-start;
        }
        &__header,
        &__price {
          padding-left: 30px;
          padding-right: 30px;
        }
        &__button {
          background: transparent;
          padding: 0;
          position: absolute;
          bottom: 50px;
          width: 100%;
          button {
            background-color: rgba(#000, 0.47);
            border-color: rgba(#fff, 0.59);
            color: white;
            &:hover {
              background-color: rgba(#000, 0.8);
              border-color: rgba(#fff, 0.8);
            }
          }
        }
      }
    }
  }
}
